import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Curso from "../components/curso";
import Tree from "../images/cursos/Tree.webp";
import Rocks from "../images/cursos/Rocks.webp";

const Fundamentos = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted ? (
    <Layout>
      <Curso
        title="Fundamentos de la filosofía budista"
        phrase="Aceptando la realidad en sus propios términos."
        time="10 Horas"
        class="8 Sesiones"
        students="3 - 6 Estudiantes"
        firstImg={Tree}
        secondImg={Rocks}
        description="En este curso veremos los fundamentos filosóficos del budismo, siempre enfocándonos en la aplicación concreta y personal de cada uno de ellos. Este curso no es religioso, sino filosófico-secular."
        list={[
          "Introducción al budismo y a la historia de Buda.",
          "El Karma y sus 12 leyes.",
          "Las tres Marcas de la Existencia.",
          "Las Cuatro Nobles Verdades.",
          "El Noble Óctuple Camino.",
        ]}
        duracion="8 sesiones de entre 60 y 75 min."
        cupo="mínimo 3 personas, máximo 6 personas."
        precio=" $2,000 MXN / $120 USD aprox."
        top="10%"
        imgWidth="16%"
        right="5%"
        topMob="15%"
        rightMob="1%"
        imgWidthMob="20%"
      />
    </Layout>
  ) : null;
};

export default Fundamentos;
